import { Link } from 'gatsby';
import React from 'react';
import { WpAcfLink } from '~/_generated/types';

export enum ButtonType {
	Button = 'button',
	Text = 'text',
	ButtonOutline = 'button-outline',
}

export enum ButtonSize {
	Small = 'small',
	Slim = 'slim',
}

export enum ButtonColor {
	Default = 'default',
	White = 'white',
	Patina = 'patina',
	Forest = 'forest',
	Elm = 'elm',
	Zest = 'zest',
}

export interface ButtonProps {
	link: WpAcfLink;
	type: ButtonType;
	size: ButtonSize;
	color: ButtonColor;
}

export const Button = ({ link, type, size, color, className }: ButtonProps) => {
	if (!link) return null;

	let typeClasses = '';
	if (className) {
		typeClasses = className;
	}

	if (type === ButtonType.Button) {
		typeClasses = typeClasses + ' button';
		if (size === ButtonSize.Slim) {
			typeClasses = typeClasses + ' button--slim';
		}
		if (size === ButtonSize.Small) {
			typeClasses = typeClasses + ' button--small';
		}
		if (color === ButtonColor.White) {
			typeClasses = typeClasses + ' button--white';
		}
		if (color === ButtonColor.Patina) {
			typeClasses = typeClasses + ' button--patina';
		}
		if (color === ButtonColor.Forest) {
			typeClasses = typeClasses + ' button--forest';
		}
		if (color === ButtonColor.Elm) {
			typeClasses = typeClasses + ' button--elm';
		}
		if (color === ButtonColor.Zest) {
			typeClasses = typeClasses + ' button--zest';
		}
	} else {
		if (typeClasses === '') {
			typeClasses = 'button';
		} else {
			typeClasses = typeClasses + ' button';
		}
	}

	return (
		<Link to={link?.url || '#'} className={`${typeClasses}`}>
			<span className="">{link?.title}</span>
		</Link>
	);
};
