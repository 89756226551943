import React from 'react';

export const Facebook = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.6 9.1c0-1.6-.5-3.3-1.4-4.6-.9-1.4-2.2-2.5-3.7-3.2C11 .6 9.3.4 7.7.6s-3.2.9-4.4 2S1.2 5 .7 6.6s-.5 3.3 0 4.8c.4 1.6 1.3 3 2.6 4.1s2.8 1.8 4.4 2.1v-6H5.5V9.1h2.2V7.2C7.7 5 9 3.8 11 3.8c.6 0 1.3.1 1.9.2v2.1h-1.1c-.3 0-.7 0-.9.2-.3.2-.4.5-.5.8V9h2.4l-.4 2.5h-2v6c2-.3 3.9-1.4 5.2-2.9 1.3-1.5 2-3.5 2-5.5z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const LinkedIn = () => {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.4696 0.105609H1.52957C1.33915 0.102964 1.15007 0.137856 0.973136 0.208292C0.796201 0.278729 0.634874 0.383329 0.498368 0.516121C0.361862 0.648912 0.252852 0.807293 0.177562 0.982218C0.102273 1.15714 0.0621787 1.34519 0.0595703 1.53561V18.6756C0.0621787 18.866 0.102273 19.0541 0.177562 19.229C0.252852 19.4039 0.361862 19.5623 0.498368 19.6951C0.634874 19.8279 0.796201 19.9325 0.973136 20.0029C1.15007 20.0734 1.33915 20.1083 1.52957 20.1056H18.4696C18.66 20.1083 18.8491 20.0734 19.026 20.0029C19.2029 19.9325 19.3643 19.8279 19.5008 19.6951C19.6373 19.5623 19.7463 19.4039 19.8216 19.229C19.8969 19.0541 19.937 18.866 19.9396 18.6756V1.53561C19.937 1.34519 19.8969 1.15714 19.8216 0.982218C19.7463 0.807293 19.6373 0.648912 19.5008 0.516121C19.3643 0.383329 19.2029 0.278729 19.026 0.208292C18.8491 0.137856 18.66 0.102964 18.4696 0.105609V0.105609ZM6.08957 16.8456H3.08957V7.84561H6.08957V16.8456ZM4.58957 6.58561C4.17583 6.58561 3.77904 6.42125 3.48648 6.1287C3.19393 5.83614 3.02957 5.43935 3.02957 5.02561C3.02957 4.61187 3.19393 4.21508 3.48648 3.92252C3.77904 3.62997 4.17583 3.46561 4.58957 3.46561C4.80927 3.44069 5.03175 3.46246 5.24245 3.52949C5.45314 3.59652 5.64731 3.7073 5.81223 3.85457C5.97715 4.00184 6.1091 4.18229 6.19944 4.38409C6.28979 4.58589 6.33649 4.8045 6.33649 5.02561C6.33649 5.24671 6.28979 5.46532 6.19944 5.66713C6.1091 5.86893 5.97715 6.04938 5.81223 6.19665C5.64731 6.34392 5.45314 6.4547 5.24245 6.52173C5.03175 6.58875 4.80927 6.61052 4.58957 6.58561V6.58561ZM16.9096 16.8456H13.9096V12.0156C13.9096 10.8056 13.4796 10.0156 12.3896 10.0156C12.0522 10.0181 11.7238 10.1239 11.4484 10.3188C11.1731 10.5137 10.9641 10.7883 10.8496 11.1056C10.7713 11.3406 10.7374 11.5882 10.7496 11.8356V16.8356H7.74957C7.74957 16.8356 7.74957 8.65561 7.74957 7.83561H10.7496V9.10561C11.0221 8.63271 11.4185 8.24313 11.896 7.97881C12.3735 7.71449 12.9141 7.58546 13.4596 7.60561C15.4596 7.60561 16.9096 8.89561 16.9096 11.6656V16.8456Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const LinkedInLetters = () => {
	return (
		<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.45516 11.5488H8.10387V32.9343H1.45516V11.5488ZM4.77969 0.918396C6.9047 0.918396 8.63165 2.64534 8.63165 4.77392C8.63165 6.90035 6.9047 8.6273 4.77969 8.6273C2.64969 8.6273 0.927734 6.90035 0.927734 4.77392C0.927734 2.64534 2.64969 0.918396 4.77969 0.918396Z"
				fill="currentColor"
			/>
			<path
				d="M12.2715 11.5488H18.6469V14.4732H18.7377C19.6251 12.7911 21.7939 11.0193 25.0265 11.0193C31.7572 11.0193 32.9995 15.4471 32.9995 21.2053V32.9343H26.3558V22.5345C26.3558 20.0538 26.3127 16.864 22.9019 16.864C19.443 16.864 18.9152 19.5678 18.9152 22.3563V32.9343H12.2715V11.5488Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const Twitter = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 3.8c-.6.8-1.2 1.5-2 2.1v.5c0 1.1-.1 2.2-.5 3.3-.3 1.1-.8 2.2-1.5 3.2-.6 1-1.4 1.9-2.3 2.7-.9.8-2 1.4-3.3 1.8-1.3.5-2.7.7-4.1.7-2.3 0-4.4-.6-6.3-1.9.3 0 .6.1 1 .1 1.9 0 3.6-.6 5.1-1.8-.9 0-1.7-.3-2.4-.8s-1.2-1.2-1.5-2c.3 0 .6.1.8.1.4 0 .7 0 1.1-.2-.9-.2-1.7-.7-2.4-1.4-.6-.7-.9-1.6-.9-2.6.6.3 1.2.5 1.9.5-.6-.4-1-.8-1.3-1.4C1 6 .8 5.4.8 4.7s.2-1.5.6-2.1c1 1.2 2.3 2.3 3.7 3s3.1 1.2 4.8 1.3c-.1-.3-.2-.6-.2-.9 0-1.1.4-2.1 1.2-2.9s1.8-1.2 2.9-1.2 2.2.4 3 1.3c.9-.2 1.8-.5 2.6-1-.3 1-.9 1.7-1.8 2.3.8-.1 1.6-.4 2.4-.7z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const YouTube = () => {
	return (
		<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23 5.81545C23.0495 4.38411 22.7365 2.96347 22.09 1.68545C21.6514 1.16105 21.0427 0.807163 20.37 0.685453C17.5875 0.432978 14.7936 0.329497 12 0.375453C9.21667 0.327411 6.43274 0.427553 3.66003 0.675453C3.11185 0.77517 2.60454 1.03229 2.20003 1.41545C1.30003 2.24545 1.20003 3.66545 1.10003 4.86545C0.954939 7.02302 0.954939 9.18789 1.10003 11.3455C1.12896 12.0209 1.22952 12.6913 1.40003 13.3455C1.5206 13.8505 1.76455 14.3178 2.11003 14.7055C2.51729 15.1089 3.03641 15.3807 3.60003 15.4855C5.75594 15.7516 7.92824 15.8619 10.1 15.8155C13.6 15.8655 16.67 15.8155 20.3 15.5355C20.8775 15.4371 21.4112 15.165 21.83 14.7555C22.11 14.4754 22.3191 14.1326 22.44 13.7555C22.7977 12.6581 22.9733 11.5096 22.96 10.3555C23 9.79545 23 6.41545 23 5.81545ZM9.74003 10.9555V4.76545L15.66 7.87545C14 8.79545 11.81 9.83545 9.74003 10.9555Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const ArrowRight = () => (
	<svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.3434 0.717131L14.3632 0.697848C14.9342 0.188381 15.8177 0.214791 16.3564 0.755706L25.2948 9.46231C25.8387 9.9941 25.8397 10.8537 25.2969 11.3865L16.3534 20.098C15.8147 20.6389 14.9312 20.6653 14.3602 20.1559C14.0893 19.9022 13.9362 19.5541 13.932 19.1887C13.9278 18.8233 14.0758 18.4722 14.3404 18.2144L19.6125 13.0791C19.8354 12.8619 19.9032 12.5341 19.7823 12.25C19.6615 11.9658 19.376 11.7811 19.0603 11.7821L1.67955 11.78C1.30552 11.781 0.946063 11.6339 0.684568 11.3731C0.422012 11.1133 0.277192 10.7611 0.284483 10.3968C0.317824 9.64173 0.96172 9.05107 1.73584 9.06222L19.0656 9.05729C19.3824 9.05729 19.6679 8.87257 19.7877 8.58741C19.9085 8.30324 19.8408 7.97546 19.6168 7.75928L14.3469 2.62604C13.8103 2.09731 13.8082 1.24684 14.3437 0.717068L14.3434 0.717131Z"
			fill="currentColor"
		/>
	</svg>
);

export const ChevronRight = () => (
	<svg
		className="svg-chevron-right"
		width="6"
		height="10"
		viewBox="0 0 6 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path fill="currentColor" d="m1.8 9.3-1.3-1L3.3 5 .5 1.7 1.8.6 5.5 5 1.8 9.3z" />
	</svg>
);

export const ChevronDown = () => (
	<svg
		className="svg-chevron-down"
		width="10"
		height="6"
		viewBox="0 0 10 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path fill="currentColor" d="M.7 1.8 1.8.5 5 3.3 8.3.5l1.1 1.3L5 5.5.7 1.8z" />
	</svg>
);

export const Tick = () => (
	<svg className="svg-tick" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill="currentColor" d="M5.4 11.2 2.1 7.8 1 8.9l4.4 4.6L15 3.7l-1.1-1.2-8.5 8.7z" />
	</svg>
);

export const CircleTick = () => (
	<svg
		className="svg-circle-tick"
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path fill="currentColor" d="M8 .5c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z" />
		<path fill="#FFFFFF" d="m6.4 12.5-4-4 1.1-1.1 2.9 2.8 6.1-6 1.1 1.1z" />
	</svg>
);

export const CircleLine = () => (
	<svg
		className="svg-circle-line"
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="9" cy="9" r="7.6" fill="currentColor" />
		<path fill="#FFFFFF" d="M5.3 8.2h7.3v1.7H5.3z" />
	</svg>
);

export const Blocks = () => (
	<svg className="svg-blocks" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill="currentColor" d="M1 1h8v10H1V1zm0 18h8v-6H1v6zm10 0h8V9h-8v10zm0-18v6h8V1h-8z" />
	</svg>
);

export const Arrows = () => (
	<svg className="svg-arrows" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="M19 1.6v5.6h-2.2V4.3l-6.3 6.3H1V8.4h8.5l5.6-5.6h-2.9V.5h5.6c.7 0 1.2.5 1.2 1.1zm-2.2 13.1-3.6-3.6-1.6 1.6 3.6 3.6h-2.9v2.2h5.6c.6 0 1.1-.5 1.1-1.1v-5.6h-2.2v2.9z"
		/>
	</svg>
);

export const Search = () => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="m13.2 11.9-3.1-3.1c.8-1.1 1.1-2.4.9-3.7C10.6.8 5-1.1 2.1 2c-4.5 4.6 1.5 11.6 6.8 8l3 3c.8 1 2.1-.2 1.3-1.1zM2.5 3.6c2.8-4 9-.7 7.2 3.8C7.1 12.8-.6 8.7 2.5 3.6z"
		/>
	</svg>
);

export const Logo = () => (
	<svg width="116" height="33" viewBox="0 0 116 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="M72.4 29.9c-.2-.2-.3-.4-.3-.8v-7.5c0-1.4-.5-2.5-1.4-3.3-.9-.8-2.4-1.2-4.3-1.2-1.8 0-3.2.4-4.3 1.1-1.1.7-1.6 1.7-1.6 3v.6h2.8v-.4c0-.6.2-1 .7-1.4.5-.4 1.2-.6 2.3-.6 1.1 0 1.9.2 2.3.6.4.4.6 1 .6 1.8v1.4c-3 0-5.4.4-7 1.1-1.6.7-2.4 2-2.4 3.9 0 1.6.5 2.6 1.4 3.2 1 .6 2.1.9 3.3.9 1.1 0 2-.2 2.8-.6.8-.4 1.5-.9 2-1.6h.2c.1.6.4 1.1.9 1.5.5.4 1 .5 1.7.5.8 0 1.5-.1 2-.4v-1.9h-1c-.2.3-.5.3-.7.1zm-3.1-3.4c0 1.2-.4 2.1-1.1 2.7-.7.7-1.7 1-2.8 1-.9 0-1.5-.2-2-.5-.4-.3-.6-.8-.6-1.5 0-1 .6-1.7 1.7-2 1.1-.4 2.7-.6 4.8-.6v.9zM54.7 13.1h3.1v19.1h-3.1v-8.4H45v8.4h-3.1V13.1H45v8h9.7v-8zm60.5 9.1v9.9h-2.9v-9.6c0-1.1-.3-1.9-.8-2.3-.5-.4-1.2-.7-2.1-.7-.7 0-1.3.2-1.8.5-.6.3-1 .8-1.3 1.4-.3.6-.5 1.3-.5 2.1v8.6H103V17.5h2.3l.3 2h.2c.5-.7 1.2-1.3 2-1.7.8-.4 1.8-.6 2.8-.6 3.1 0 4.6 1.7 4.6 5zm-30.7-4.7h2.9L82 32.2h-3l-5.4-14.7h3l2.5 7c.3.9.8 2.4 1.4 4.5h.1c.5-2 1-3.5 1.4-4.5l2.5-7zm10.2-.3c-2.4 0-4.1.6-5.3 1.9-1.1 1.2-1.7 3.2-1.7 5.8s.6 4.6 1.7 5.8c1.2 1.2 2.9 1.9 5.3 1.9 1.9 0 3.4-.5 4.6-1.4 1.2-.9 1.8-2.3 1.8-4h-2.8c0 1-.3 1.8-1 2.3-.6.5-1.5.8-2.6.8-1.4 0-2.4-.4-3-1.1-.6-.8-1-1.9-1.1-3.5H101v-1.1c0-2.5-.5-4.4-1.6-5.5-1-1.3-2.6-1.9-4.7-1.9zm-4 6.2c.1-1.4.5-2.3 1.1-3 .6-.6 1.6-.9 2.8-.9 2.3 0 3.4 1.3 3.4 3.9h-7.3z"
		/>
		<path
			fill="currentColor"
			d="M30.6 9.5v22.7h-4.7V12.3L20.2 9v9.9h-9.3V9l-5.5 3.4v19.9H.6V9.5l14.9-9 15.1 9zM11 32.3h9.2v-9H11v9z"
		/>
	</svg>
);

export const LogoMark = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M37.5 11.9v26.8H32V15.2l-6.8-4v11.7H14.4V11.3l-6.5 4v23.5H2.5V11.9L19.9 1.3l17.6 10.6zM14.6 38.7h10.7V28.1H14.6v10.6z"
			fill="currentColor"
		/>
	</svg>
);

export enum IconType {
	None = 'none',
	ArrowRight = 'arrowRight',
	ChevronRight = 'chevronRight',
	ChevronDown = 'chevronDown',
	LogoMark = 'logoMark',
	Logo = 'logo',
	Search = 'search',
	CircleTick = 'circleTick',
	Tick = 'tick',
	CircleLine = 'circleLine',
	Blocks = 'blocks',
	Arrows = 'arrows',
	LinkedIn = 'linkedIn',
	LinkedInLetters = 'linkedInLetters',
	Twitter = 'twitter',
	Facebook = 'facebook',
}

const IconMap = {
	arrowRight: ArrowRight,
	logoMark: LogoMark,
	logo: Logo,
	search: Search,
	chevronRight: ChevronRight,
	chevronDown: ChevronDown,
	circleTick: CircleTick,
	tick: Tick,
	circleLine: CircleLine,
	blocks: Blocks,
	arrows: Arrows,
	linkedIn: LinkedIn,
	linkedInLetters: LinkedInLetters,
	facebook: Facebook,
	twitter: Twitter,
};

interface IconProps {
	type: string;
}

export const Icon = ({ type }: IconProps) => {
	if (type === IconType.None || !type) return null;
	return IconMap[type]();
};
