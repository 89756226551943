import React from "react";
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';

export default function Cta({ heading, link }) {

	return (
		<section className="text-white bg-sea" role="contentinfo">
			<div className="container text-center  lg:pt-[80px]">
				{/* CTA */}
				<div className="py-16 lg:border-patina lg:border lg:rounded-lg lg:px-12 lg:py-20">
					<p className="text-h2 max-w-[760px] mx-auto mb-10">{ heading }</p>
					<Button
						className="w-full text-white sm:w-full sm:max-w-sm hover:bg-white hover:bg-deepsea"
						link={{ url: link?.url, title: link?.title }}
						type="button"
						color={ButtonColor.Forest}
					/>
				</div>
        <hr className="border-patina opacity-[0.5] lg:hidden" />
      </div>
   </section>

  );
};