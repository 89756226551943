import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';

export default function Layout({ wp, children }) {
	const seo = useSiteInfoAssets();

	return (
		<SEOContext.Provider value={{ global: seo }}>
			<Header data={wp?.header} />
			<main className="main-content mt-[-62px] lg:mt-[-85px]">{children}</main>
			<Footer data={wp?.footer} />
		</SEOContext.Provider>
	);
}
