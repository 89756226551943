import React from 'react';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { Link } from '~/components/elements/Link';
import { Notification } from '~/components/elements/Notification';
import { Icon, IconType } from '~/components/elements/Icon';
import Image from '~/components/elements/Image';
import Cta from '~/components/flexible/Cta';
import { NewsletterForm } from '~/components/elements/forms/NewsletterForm';


export default function Footer({ data }) {
  const footer = data?.footerOptions.footer;

  return (
    <footer className="text-white bg-sea" role="contentinfo">
    <Cta
        heading={footer?.footerCta.heading}
        link={footer?.footerCta.link}
      />
      <div className="container">
        <div className="section-md lg:pt-[90px]">
          <div className="grid grid-cols-12 gap-6">
            {footer?.columns.map(({ type, heading, links }, i) => (
              <div className={`col-span-6 ${type === '2' ? 'lg:col-span-4' : 'lg:col-span-2'}`} key={i}>
                <p className="mb-3 text-bold text-patina">{heading}</p>
                <ul className='space-y-2'>
                  {links.map(({ link, comingSoon }, i) => (
                    <li key={i}>
                      {comingSoon ? (
                        <>
                          <Link
                            faux
                            url={link.url}
                            title={link.title}
                            target={link.target}
                            className="inline-block mr-3 duration-300 ease-in-out pointer-events-none text-patina transition-color"
                          />
                          <Notification title="Coming soon" background="bg-zest hidden md:inline-block" />
                        </>
                      ) : (
                        <Link
                          url={link.url}
                          title={link.title}
                          target={link.target}
                          className="inline-block text-white duration-300 ease-in-out hover:text-zest transition-color"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="justify-end hidden lg:flex lg:col-span-2">
              <i className="block w-[40px] h-[40px] fw-icon lg:ml-auto">
                <Icon type={IconType.LogoMark} />
              </i>
            </div>
          </div>

          <div className="gap-6 lg:grid lg:grid-cols-12">
            <div className="mt-12 lg:col-span-4">
              {/* <Button
                className="w-full sm:w-auto"
                link={{
                  url: footer?.footerButton.url,
                  title: footer?.footerButton.title,
                  target: footer?.footerButton.title,
                }}
                type="button"
                color={ButtonColor.White}
              /> */}
            </div>
            <div className="mt-8 lg:col-span-6">
              <p className="mb-1 text-xl font-heading">Get the latest in customer retention</p>
              <NewsletterForm buttonColour='forest'/>
            </div>
          </div>

          <div className="mt-10 md:mt-20 mb-10 md:mb-0 lg:mt-[126px] md:flex items-center text-sm w-full text-center lg:text-left">
            <ul className="flex justify-center gap-6 mt-6 md:justify-start md:w-1/3">
              {footer?.socialMenu.map(({ socialIcon, socialLink }, i) => (
                <li key={i} className="transition duration-200 ease-in-out hover:opacity-50">
                  <a
                    href={socialLink.url}
                    target={socialLink.target}
                    title={socialLink.title}
                    className="text-white transition-all hover:text-zest"
                  >
                    <i className="block w-[20px] h-[20px] fw-icon">
                      <Image image={socialIcon} />
                    </i>
                  </a>
                </li>
              ))}
            </ul>
            <ul className="flex flex-col sm:flex-row justify-center gap-4 mt-6 leading-4 md:w-1/3">
              {footer?.legalMenuItems.map(({ legalLink }, i) => (
                <li className="duration-300 ease-in-out hover:text-zest transition-color" key={i}>
                  <Link url={legalLink.url} target={legalLink.target} title={legalLink.title} />
                </li>
              ))}
            </ul>
            <p className="flex justify-center mt-6 md:justify-end md:w-1/3">
              <span>&copy; {new Date().getFullYear()} Haven. All Rights Reserved.</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
