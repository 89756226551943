import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

// @todo setup these props properly

interface ImageProps {
	image?: any;
	objectFit?: any;
	objectPosition?: any;
	className?: any;
	loading?: any;
}

export function Image({ image, objectFit, objectPosition, className, loading, ...other }: ImageProps) {
	let url = image?.localFile?.publicURL;
	if (!url) url = image;
	return (
		<>
			{image?.localFile?.childImageSharp ? (
				<GatsbyImage
					image={getImage(image?.localFile)}
					alt={image?.altText}
					imgStyle={{ objectFit: objectFit || 'contain' }}
					draggable="false"
					loading={loading}
					className={`${className}`}
					{...other}
				/>
			) : (
				<img {...other} src={url} alt={image?.altText} draggable="false" className={`w-full ${className}`} />
			)}
		</>
	);
}

export default Image;
