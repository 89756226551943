import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import Image from '~/components/elements/Image';
import { Notification } from '~/components/elements/Notification';

export const Submenus = ({ submenu }) => (
  <motion.div
    initial={{ opacity: 0, y: 30 }}
    animate={{ opacity: 1, y: 10 }}
    exit={{ opacity: 0, y: 30 }}
    transition={{ duration: 0.4 }}
    className={` absolute top-14 bg-white shadow submenu-shadow rounded-lg max-w-[69rem] ${
      submenu.position === '2' ? 'left-0 right-0 mx-auto' : ''
    } `}
  >
    <nav className="flex px-4 py-4 space-x-4 md:px-4 md:py-4 lg:space-x-5">
      {submenu.columns.map(({ menuItems }, i) => (
        <ul key={i} className="space-y-4 lg:space-y-2">
          {menuItems.map(({ icon, link, description, comingSoon }, i) => (
            <li
              key={i}
              className={` w-[345px]  ${
                comingSoon ? `opacity-50 pointer-events-none` : `transition ease-in-out duration-200  `
              }`}
            >

              <Link className="relative flex items-start gap-6 px-3 py-2 group" to={link.url} title={link.heading}>
                <div className="absolute inset-0 z-0 block transition-all duration-300 ease-in-out transform scale-75 rounded-md opacity-0 bg-pearl group-hover:scale-100 group-hover:opacity-100"></div>
                {icon && (
                  <figure className="flex items-center w-10 h-10 my-1 rounded-md shrink-0 align-center">
                    <Image image={icon} className="w-full h-auto" />
                  </figure>
                )}
                <div className="z-10">
                  <h3 className="my-1 font-bold font-body">
                    {link.title}
                    {comingSoon && (
                      <Notification
                        title="Coming soon"
                        background="bg-forest"
                        textColour="text-white"
                        className="my-1 ml-4"
                      />
                    )}
                  </h3>
                  {description && <p className="mt-1 text-sm">{description}</p>}

                </div>
              </Link>
            </li>
          ))}
        </ul>
      ))}
    </nav>
  </motion.div>
);
