import React from 'react';

export const Notification = ({ className, title, background, textColour }) => {
	let bg = '',
		tc = '';
	if (background) {
		bg = background;
	} else {
		bg = 'bg-zest ';
	}
	if (textColour) {
		tc = textColour;
	} else {
		tc = 'text-deepsea ';
	}
	let classes = ['whitespace-nowrap px-[6px] py-[3px] rounded-[6px] leading-3 text-[12px]'];
	if (className) {
		classes.push(className);
	}
	classes.push(bg);
	classes.push(tc);
	return <span className={classes.join(' ')}>{title}</span>;
};
