import React, { useState } from "react";
import axios from "axios";

export const NewsletterForm = ({buttonColour}) => {

  const [ formSent, setFormStatus ] = useState('initial')

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    setFormStatus('loading');
    console.log('loading');
    axios({
      method: "post",
      url: "https://getform.io/f/18bc1c3c-5cea-4aa6-b5f3-3e21740f6f48",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
        console.log('success');
        setFormStatus('success');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
        console.log('fail');
        setFormStatus('fail');
      });
  };

  return (
    <form className="sm:flex w-full gap-2 flex-wrap"
      onSubmit={handleOnSubmit}>
        <input
          className="w-full sm:w-auto px-4 md:px-6 py-[.85rem] rounded-md flex-1 my-2 leading-4 border-2 border-white"
          type="email"
          required
          placeholder="Enter your email address"
          name='email'
        />
        {formSent === 'loading' ? (
          <button
            className={`relative w-full max-h-[50px] sm:w-auto my-2 button button--slim button--${buttonColour} min-w-[8rem]`}
            type="submit"
            >
            <svg className="spinner my-auto" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
              <>Sending</>
          </button>
        ) : (
          <button
            className={`relative w-full max-h-[50px] sm:w-auto my-2 button button--slim button--${buttonColour} min-w-[8rem]`}
            type="submit"
            >
            <>Subscribe</>
           </button>
          )}
        {formSent === 'success' ? (
          <div className="flex items-center justify-center w-full">
            <p className="font-bold md:text-[12px]">Thank you, you're now subscribed to our newsletter.</p>
          </div>
        ) : formSent === 'fail' ? (
          <p>Form error</p>
        ) : (
          <></>
         )}
    </form>
    );
};