import React, { useState, useEffect } from 'react';
import useDocumentScrollThrottled from '~/hooks/useDocumentScrollThrottled';
import { Icon, IconType } from '~/components/elements/Icon';
import { Button } from '~/components/elements/button/Button';
import { Link } from '~/components/elements/Link';
import { Submenus } from '~/components/global/Submenus';
import Image from '~/components/elements/Image';
import { Notification } from '~/components/elements/Notification';
import { AnimatePresence, motion } from 'framer-motion';

export default function Header({ data }) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(1);
  const [openMenu, setOpenMenu] = useState(null);

  const header = data?.headerOptions.header;
  return (
    <header
      role="banner"
      className={`relative section-header flex items-center transition-all ease-in-out duration-300 delay-200 top-0 left-0 right-0 z-20 h-[62px] lg:h-[85px] ${
        openMobileMenu ? '!bg-pearl !fixed top-0 left-0 right-0' : ''
      }`}
    >
      <div className="flex justify-between w-full transition-all duration-200 delay-200 container-full">
        <figure className="flex items-center">
          <a href="/" className="block text-forest relative top-[-2px]">
            <i className="block  w-[90px] h-auto lg:w-[100px] lg:h-[28px] fw-icon">
              <Icon type={IconType.Logo} />
            </i>
          </a>
        </figure>
        <div className="items-center justify-center flex-1 hidden lg:flex">
          <ul className="flex space-x-4 xl:space-x-8">
            {header?.menu.map(({ link, showSubmenu, submenu }, i) => (
              <motion.li
                onHoverStart={() => {
                  setOpenMenu(i);
                }}
                onHoverEnd={() => setOpenMenu(null)}
                className={`flex items-center py-4 font-medium ${showSubmenu ? 'parent-menu' : ''}`}
                key={i}
              >
                {showSubmenu ? (
                  <SubmenuLink link={link} submenu={submenu} openMenu={openMenu === i} />
                ) : (
                  <Link url={link.url} title={link.title} className="text-[17px] transition-colors hover:text-forest" />
                )}
              </motion.li>
            ))}
          </ul>
        </div>
        <div className="lg:w-[135px] flex justify-end items-center">
          <div className="hidden w-full lg:block">
            {header?.buttons.map(({ button }, i) => (
              <Button
                link={{ url: button.link.url, title: button.link.title }}
                type="button"
                color="forest"
                size="slim"
                className="!block max-w-40"
                key={i}
              />
            ))}
          </div>
          <div className="flex justify-end lg:hidden">
            <div
              onClick={() => setOpenMobileMenu((cur) => !cur)}
              className="flex items-center justify-end w-10 h-8 mr-0 cursor-pointer"
            >
              <MobileMenuButton openMobileMenu={openMobileMenu} />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {openMobileMenu ? (
            <motion.div
              key={`mobileMenu`}
              initial={{ y: '-100%' }}
              animate={{ y: 0 }}
              exit={{ y: '-100%' }}
              transition={{ duration: 0.3 }}
              className="fixed lg:hidden bg-pearl top-[60px] bottom-0 inset-x-0 h-screen overflow-hidden overflow-y-auto font-Moderat"
            >
              <ul className="px-6 mt-8">
                {header?.menu.map((item, i) => (
                  <MobileMenuItem key={i} {...item} i={i} setActiveMenu={setActiveMenu} activeMenu={activeMenu === i} />
                ))}
              </ul>
              <div className="block w-full px-6 mt-8">
            {header?.buttons.map(({ button }, i) => (
              <Button
                link={{ url: button.link.url, title: button.link.title }}
                type="button"
                color="forest"
                className="!block max-w-40"
                key={i}
              />
            ))}
          </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </header>
  );
}

export const MobileMenuItem = ({ link, showSubmenu, submenu, setActiveMenu, activeMenu, i }) => {
  return (
    <li className="py-4 font-bold border-b t-20 border-elm border-opacity-20">
      {!showSubmenu ? (
        <a href={link.url}>
          <span>{link.title}</span>
        </a>
      ) : (
        <div>
          <div
            onClick={() => setActiveMenu(activeMenu ? null : i)}
            className="flex flex-row items-center justify-between cursor-pointer"
          >
            <span>{link.title}</span>
            <div className={`transition transform duration-200 ease-in-out ${activeMenu ? 'rotate-180' : ''}`}>
              <ArrowDown />
            </div>
          </div>
          {activeMenu && (
            <div className="mt-6 space-y-4">
              {submenu.columns.map(({ menuItems }, i) => (
                <ul key={i} className="space-y-2 lg:space-y-6">
                  {menuItems.map(({ icon, link, comingSoon }, i) => (
                    <motion.li
                      key={i}
                      className={`${
                        comingSoon
                          ? `opacity-50 pointer-events-none`
                          : `transition ease-in-out duration-200 hover:opacity-50`
                      }`}
                    >
                      <a className="flex items-center gap-5" href={link.url} title={link.heading}>
                        {icon && (
                          <figure className="flex items-center w-12 h-12 my-1 rounded-md shrink-0 align-center">
                            <Image image={icon} className="w-full h-auto" />
                          </figure>
                        )}
                        <div>
                          <h3 className="text-[17px] font-body font-bold my-1">
                            {link.title}
                            {comingSoon && (
                              <Notification
                                title="Coming soon"
                                background="bg-forest"
                                textColour="text-white"
                                className="my-1 ml-4"
                              />
                            )}
                          </h3>
                        </div>
                      </a>
                    </motion.li>
                  ))}
                </ul>
              ))}
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export function SubmenuLink({ link, submenu, openMenu }) {
  return (
    <>
      <Link
        url={link.url}
        title={link.title}
        className="transition-colors hover:text-forest text-[17px]"
        iconAfter="ChevronDown"
      />
      <AnimatePresence>{openMenu && <Submenus submenu={submenu} />}</AnimatePresence>
    </>
  );
}

export const ArrowDown = () => (
  <svg width={11} height={7} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.2 1.035 5.72 6.059l4.522-5.024"
      stroke="#1C4044"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MobileMenuButton = ({ openMobileMenu }) => (
  <div className="w-[25px] space-y-1">
    <div
      className={`bg-deepsea h-[1.5px] transition-transform duration-500`}
      style={{ transform: openMobileMenu ? 'rotate(45deg) translateY(3.5px)' : '' }}
    ></div>
    <div
      className={`bg-deepsea h-[1.5px] transition-transform duration-500`}
      style={{ transform: openMobileMenu ? 'rotate(-45deg) translateY(-3.5px)' : '' }}
    ></div>
  </div>
);
