import React from 'react';
import { ChevronRight, ChevronDown } from '~/components/elements/Icon';

export const Link = ({ className, title, url, iconAfter, faux, isAfter }) => {
  if (faux) {
    return (
      <span className={className + ' inline-flex'}>
        <span>{title}</span>
        {iconAfter && <IconInner iconAfter={iconAfter} />}
      </span>
    );
  } else {
    return (
      <a className={className + ' inline-flex'} href={url} title={title}>
        <span dangerouslySetInnerHTML={{ __html: title }}/>
        {iconAfter && <IconInner iconAfter={iconAfter} />}
      </a>
    );
  }
};

export const IconInner = ({ iconAfter }) => {
  if (iconAfter === 'ChevronRight') {
    return (
      <span className="ml-2 flex items-center translate-y-[-1px]">
        <ChevronRight />
      </span>
    );
  }
  if (iconAfter === 'ChevronDown') {
    return (
      <span className="ml-2 flex items-center">
        <ChevronDown />
      </span>
    );
  } else {
    return (
      <span className="ml-2 flex items-center translate-y-[-1px]">
        <ChevronRight />
      </span>
    );
  }
};
